import { render, staticRenderFns } from "./zavada-hist.tab.vue?vue&type=template&id=fe857064&scoped=true&"
import script from "./zavada-hist.tab.vue?vue&type=script&lang=ts&"
export * from "./zavada-hist.tab.vue?vue&type=script&lang=ts&"
import style0 from "./zavada-hist.tab.vue?vue&type=style&index=0&id=fe857064&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe857064",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VChip,VChipGroup,VIcon,VSimpleTable})
