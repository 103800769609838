var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.komunikace)?_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1"}},[_vm._v(" Vyberte vzor ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" Vyplnění proměnných ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" Výběr závad ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" Dokončení ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',[_c('cms-vzor-komunikace-list',{attrs:{"selectable":""},on:{"onChangeSelected":_vm.vzorSelected}})],1),_c('v-card-actions',[_c('v-spacer'),_c('cms-btn',{attrs:{"color":"primary","disabled":!_vm.komunikace.vzorKomunikaceId},on:{"click":function($event){_vm.stepper = 2}}},[_vm._v(" Pokračovat ")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[(_vm.komunikace.vzorKomunikace)?_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',[_c('div',[_c('v-row',[(_vm.komunikace.vzorKomunikace.typKomunikaceId === 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('cms-date-field',{attrs:{"label":"Datum přijetí reklamace","required":""},model:{value:(_vm.komunikace.datumPrijetiReklamace),callback:function ($$v) {_vm.$set(_vm.komunikace, "datumPrijetiReklamace", $$v)},expression:"komunikace.datumPrijetiReklamace"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('cms-datetime-picker',{attrs:{"label":"Datum a čas místního šetření","required":""},model:{value:(_vm.komunikace.datumMistnihoSetreni),callback:function ($$v) {_vm.$set(_vm.komunikace, "datumMistnihoSetreni", $$v)},expression:"komunikace.datumMistnihoSetreni"}})],1)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('cms-btn',{attrs:{"disabled":!(
                                    (_vm.komunikace.vzorKomunikace.typKomunikaceId !== 1 || _vm.komunikace.datumPrijetiReklamace) &&
                                    _vm.komunikace.datumMistnihoSetreni
                                )},on:{"click":function($event){_vm.stepper = 3}}},[_vm._v(" Pokračovat ")])],1)],1):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',[_c('cms-zavada-list',{attrs:{"selectable":""},on:{"onChangeSelected":_vm.onSelectZavadas}})],1),_c('v-card-actions',[_c('v-spacer'),_c('cms-btn',{attrs:{"disabled":!_vm.zavadas.length},on:{"click":function($event){_vm.stepper = 4}}},[_vm._v("Pokračovat")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"4"}},[(_vm.komunikace.vzorKomunikace)?_c('v-card',{staticClass:"mt-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('cms-text-field',{attrs:{"readonly":"","value":_vm.komunikace.vzorKomunikace.nazev,"label":"Název","hide-details":"auto"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('cms-text-field',{attrs:{"required":"","label":"Předmět"},model:{value:(_vm.komunikace.predmet),callback:function ($$v) {_vm.$set(_vm.komunikace, "predmet", $$v)},expression:"komunikace.predmet"}})],1),_c('v-col',{staticStyle:{"height":"450px"},attrs:{"cols":"12"}},[_c('vue-editor',{staticStyle:{"height":"380px"},model:{value:(_vm.komunikace.text),callback:function ($$v) {_vm.$set(_vm.komunikace, "text", $$v)},expression:"komunikace.text"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.generateReportLoading)?_c('span',[_vm._v("Probíhá generování reportů")]):_vm._e(),(_vm.reportUploading)?_c('span',[_vm._v("Probíhá nahrávání reportů")]):_vm._e(),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Název souboru")]),_c('th',{staticStyle:{"width":"55px"}})])]),_c('tbody',_vm._l((_vm.soubory),function(item){return _c('tr',{key:item.nazev},[_c('td',[_vm._v(_vm._s(item.nazev))]),_c('td',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":_vm.getUrl(item.cesta),"target":"_blank","color":"blue"}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Otevřít")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.remove(item)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Odstranit")])])],1)])}),0)]},proxy:true}],null,false,2177877857)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('cms-btn',{attrs:{"color":"succes","disabled":_vm.generateReportLoading || _vm.reportUploading},on:{"click":_vm.odeslat}},[_vm._v("Odeslat")])],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }